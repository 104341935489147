import { useState, useEffect } from 'react';
import { getBackgroundStyleForPreview } from '../../storyboardUtils';

import style from './style.module.css';

const ProgressBarPreview = ({ time, lowStoryboard, highStoryboard }) => {
    const [showHigh, setShowHigh] = useState(false);
    const [lastTime, setLastTime] = useState(null);

    const storyboardData = getBackgroundStyleForPreview(time, lowStoryboard, highStoryboard);

    useEffect(() => {
        if (time != lastTime) {
            setLastTime(time);
            setShowHigh(false);
        }
        if (!showHigh) {
            const timer = setTimeout(async () => {
                await fetch(storyboardData.lowPath).catch(() => {});
                await fetch(storyboardData.highPath).catch(() => {});
                setShowHigh(true);
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [lastTime, setLastTime, showHigh, time, storyboardData.highPath, storyboardData.lowPath]);

    return (
        <div draggable={false}>
            {!showHigh && <div className={style.storyboard} style={storyboardData.low} draggable={false} /> }
            {showHigh && <div className={style.storyboard} style={storyboardData.high} draggable={false} /> }
        </div>
    );
};

export default ProgressBarPreview;