import { createRef, Component } from "preact";
import PropTypes from "prop-types";
import PlayerIcon from "../PlayerIcon";
import style from "./style.module.css";

const MOBILE_DELAY = 2500;
const DESKTOP_DELAY = 1000;

class Foreground extends Component {
  constructor(props) {
    super(props);

    this.ref = createRef();
    this.previousMousePosition = { x: 0, y: 0 };
    this.mouseHoverTimeout = null;

    this.setAutoHide = (duration) => {
      clearTimeout(this.mouseHoverTimeout);
      this.mouseHoverTimeout = setTimeout(() => {
        if (this.props.show !== false) {
          this.props.onHideInterface();
        }
      }, duration);
      if (this.props.show !== true) {
        this.props.onShowInterface();
      }
    }

    this.onPlay = () => {
      if (this.props.onPlay) {
        this.props.onPlay();
      }
    };

    this.onTouch = () => {
      if (this.props.show !== true) {
        this.props.onShowInterface();
      } else {
        this.props.onHideInterface();
      }
      this.setAutoHide(MOBILE_DELAY);
    };

    this.onTouchPlay = (evt) => {
      if (this.props.show) {
        this.onPlay();
        evt.stopPropagation();
        this.setAutoHide(MOBILE_DELAY);
      }
    };

    this.onBodyMouseMove = (mouse) => {
      const isInsidePlayerArea =
        mouse.offsetX >= this.ref.current.offsetLeft &&
        mouse.offsetX <=
        this.ref.current.offsetLeft + this.ref.current.clientWidth &&
        mouse.offsetY >= this.ref.current.offsetTop &&
        mouse.offsetY <=
        this.ref.current.offsetTop + this.ref.current.clientHeight;

      if (
        isInsidePlayerArea &&
        this.previousMousePosition.x !== mouse.offsetX &&
        this.previousMousePosition.y !== mouse.offsetY
      ) {
        this.previousMousePosition.x = mouse.offsetX;
        this.previousMousePosition.y = mouse.offsetY;
        this.setAutoHide(DESKTOP_DELAY);
      }
    };
  }

  static defaultProps = {};

  render() {
    return (
      <>
        <div ref={this.ref} className={style.foreground} {...(this.props.isMobile) ? {
          onTouchStart: this.onTouch,
        } : {
            onClick: this.onPlay,
          }}>
          {this.props.isMobile && <div class={`${style.playIconIndicatorContainer}`}>
            <div className={`${style.iconContainer} ${style.hideContainer} ${this.props.show ? `${style.show}` : ''}`} onTouchStart={this.onTouchPlay}>
              <PlayerIcon
                type={this.props.isPlaying ? "PAUSE" : "PLAY"}
                color="white"
                titleAccessibility={this.props.isPlaying ? "PLAY" : "PAUSE"}
              />
            </div>
          </div>}
        </div>
      </>
    );
  }

  componentDidMount() {
    if (!this.props.isMobile) {
      document.body.addEventListener("mousemove", this.onBodyMouseMove);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying && this.props.isPlaying) {
      clearTimeout(this.mouseHoverTimeout);
      this.mouseHoverTimeout = setTimeout(() => {
          this.props.onHideInterface();
      }, this.props.isMobile ? MOBILE_DELAY : DESKTOP_DELAY);
    }
  }

  componentWillUnmount() {
    if (!this.props.isMobile) {
      document.body.removeEventListener("mousemove", this.onBodyMouseMove);
    }
  }
}

Foreground.propTypes = {
  onPlay: PropTypes.func,
  onHideInterface: PropTypes.func,
  onShowInterface: PropTypes.func,
  isMobile: PropTypes.bool,
  isPlaying: PropTypes.bool,
  showPlayIconIndicator: PropTypes.bool
};

export default Foreground;
