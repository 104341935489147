import "preact/debug";
import "preact/devtools";
import { render } from 'preact';
import canAutoPlay from 'can-autoplay';

import PlayerContainer from './components/PlayerContainer';
import { getData, getUrlArguments } from "./api";
import { bindData } from "./utils/bindData";

const detectAutoPlaySupport = async (args) => {
    if (!args.autoplay) {
        return false;
    }
    const { result } = await canAutoPlay.video({ muted: false });
    return result;
}

const main = async () => {
    const args = getUrlArguments();
    let [rawData, canAutoPlayWithSound] = await Promise.all([
        getData(args.id || null, args.key || null),
        detectAutoPlaySupport(args)
    ]);
    rawData = rawData || {};

    // Get project
    const project = rawData.getProject || {};

    // Get configuration
    let configuration = {};
    let configId = null;
    try {
        configId = rawData.getSolution ? rawData.getSolution.id : null;
        configuration = rawData.getSolution ? rawData.getSolution.configuration : rawData.getProject.defaultPlayer.configuration;
        configuration = configuration.values.reduce((acc, v) => ({ ...acc, [v.key.name]: v.computedValue }), {});
    } catch (e) { }

    const data = {
        ...bindData(project, args, configuration, configId),
    };
    window.debug = { project, args, configuration, configId, componentData: data };
    document.title = data.title;

    let playerManager = null;

    render(<PlayerContainer {...data} canAutoPlayWithSound={canAutoPlayWithSound} onInit={(cb) => {
        playerManager = cb;
    }} />, document.querySelector('#app'));

    // Catch events from SDK
    window.addEventListener('message', (evt) => {
        try {
            const data = JSON.parse(evt.data);
            if (playerManager && ['PLAY', 'PAUSE', 'MUTE', 'UNMUTE'].includes(data.type.replace('ACTION_', ''))) {
                playerManager(data.type.replace('ACTION_', ''));
            }
        }
        catch (e) { }
    });
};

/* Force reload when hash changes */
window.onhashchange = () => { window.location.reload() }

main();
