import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import PlayerIcon from '../PlayerIcon';
import ProgressBar from '../ProgressBar';
import { durationInMinutes } from "../../utils";
import style from "./style.module.css";


const PlayerActionContainer = ({
  cards = [],
  currentTime = 0,
  duration = 0,
  isPlaying = false,
  isMobile = false,
  fullScreenDisabled = false,
  isMuted = false,
  isFullScreen = false,
  show = true,
  onPlay = () => { },
  onSound = () => { },
  onRewind = () => { },
  onFastForward = () => { },
  onSubtitles = () => { },
  onFullScreen = () => { },
  onClick = () => { },
  onDisplayLegalBanner = () => { },
  t = v => v,
  subtitles = [],
  showLanguageModal = false,
  onMouseInvisible = () => { },
  onMouseVisible = () => { },
  lowStoryboard = [],
  highStoryboard = [],
  setForegroundStoryboard = () => {},
  isMouseDown = false,
  setIsMouseDown = () => {},
}
) => {
  const canDisplaySubtitles = subtitles.length > 0
  const tabIndex = show ? 0 : -1;

  return (
    <div class={`${style.playerActionContainer} ${show ? '' : style.hide}`} onMouseOver={onMouseVisible} onMouseOut={onMouseInvisible}>
      <ProgressBar currentTime={currentTime} duration={duration} cards={cards.filter((card) => card.displayedInTimeline)} onClick={onClick} isMobile={isMobile} show={show} lowStoryboard={lowStoryboard} highStoryboard={highStoryboard} onPlay={onPlay} isPlaying={isPlaying} setForegroundStoryboard={setForegroundStoryboard} isMouseDown={isMouseDown} setIsMouseDown={setIsMouseDown} />
      <div class={style.bottomContainer}>
        <div class={style.rightContainer}>
          <div class={style.rightButtonContainer} id='video-navigation-buttons'>
            <PlayerIcon tabIndex={tabIndex} type='FAST_BACK' clickHandle={onRewind} color='white' titleAccessibility={t('Rewind')} showHover={true} hoverTitle={t('Rewind')} leftPosition={0} isMobile={isMobile} />
            <PlayerIcon tabIndex={tabIndex} type={isPlaying ? 'PAUSE' : 'PLAY'} clickHandle={onPlay} color="white" titleAccessibility={isPlaying ? t('Pause') : t('Play')} showHover={true} hoverTitle={isPlaying ? t('Pause') : t('Play')} isMobile={isMobile} aria-live="assertive" />
            <PlayerIcon tabIndex={tabIndex} type='FAST_FORWARD' clickHandle={onFastForward} color='white' titleAccessibility={t('Fast forward')} showHover={true} hoverTitle={t('Fast forward')} isMobile={isMobile} />
          </div>
          <p id={style.timer}>{t('{{currentTime}} / {{duration}}', { currentTime: durationInMinutes(currentTime), duration: durationInMinutes(duration) })}</p>
        </div>
        <div class={style.leftContainer}>
          <PlayerIcon tabIndex={tabIndex} type='INFO' color='white' clickHandle={onDisplayLegalBanner} titleAccessibility={t('Legal info')} showHover={true} hoverTitle={t('Legal info')} isMobile={isMobile} />
          {!isMobile && <PlayerIcon tabIndex={tabIndex} type={isMuted ? 'SOUND_OFF' : 'SOUND_ON'} clickHandle={onSound} color='white' titleAccessibility={isMuted ? t('Unmute the video') : t('Mute the video')} showHover={true} hoverTitle={isMuted ? t('Unmute the video') : t('Mute the video')} isMobile={isMobile} />}
          {canDisplaySubtitles && <PlayerIcon tabIndex={tabIndex} type='SUBTITLES' clickHandle={onSubtitles} color='white' titleAccessibility={t('Subtitles')} showHover={true && !showLanguageModal} hoverTitle={t('Subtitles')} isMobile={isMobile} />}
          <PlayerIcon tabIndex={tabIndex} disabled={fullScreenDisabled} type={isFullScreen ? 'SHRINK' : 'FULL_SCREEN'} clickHandle={onFullScreen} color='white' titleAccessibility={fullScreenDisabled ? t('Full screen unavailable') : (isFullScreen ? t('Shrink') : t('Full screen'))} showHover={true} hoverTitle={fullScreenDisabled ? t('Full screen unavailable') : (isFullScreen ? t('Shrink') : t('Full screen'))} rightPosition={0} isMobile={isMobile} />
        </div>
      </div>
    </div>
  );

};

export default withTranslation()(PlayerActionContainer);

PlayerActionContainer.PropTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    firstFrameUrl: PropTypes.string,
    time: PropTypes.number
  })),
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  isPlaying: PropTypes.bool,
  isMobile: PropTypes.bool,
  isMuted: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  fullScreenDisabled: PropTypes.bool,
  show: PropTypes.bool,
  onPlay: PropTypes.func,
  onSound: PropTypes.func,
  onRewind: PropTypes.func,
  onFastForward: PropTypes.func,
  onSubtitles: PropTypes.func,
  onFullScreen: PropTypes.func,
  onDisplayLegalBanner: PropTypes.func,
  onClick: PropTypes.func,
  onMouseInvisible: PropTypes.func,
  t: PropTypes.func,
  subtitles: PropTypes.array,
  showLanguageModal: PropTypes.bool,
};
