import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import PlayerIcon from '../PlayerIcon';
import XLogo from './icons/x.svg';
import style from "./style.module.css";

export const ModalShare = ({ t = _ => _, onClick = () => { }, shareUrl = '' }) => {
  return (
    <div class={style.modalShareContainer}>
      <h1>{t(`Share the video`)}</h1>
      <p id={style.text}>{t('Share this video on your social networks')}</p>
      <div class={style.socialsIconsContainer}>
        <FacebookShareButton url={shareUrl} beforeOnClick={onClick}>
          <div class={style.iconContainer}>
            <PlayerIcon type='SHARE_FACEBOOK' color='white' />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} beforeOnClick={onClick} >
          <div class={style.iconContainer}>
            <XLogo class={style.icon} />
          </div>
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} beforeOnClick={onClick}>
          <div class={style.iconContainer}>
            <PlayerIcon type='SHARE_WHATSAPP' color='white' />
          </div>
        </WhatsappShareButton>
      </div>
    </div>

  );
};

export default withTranslation()(ModalShare);

ModalShare.propTypes = {
  t: PropTypes.func,
  onClick: PropTypes.func
};
