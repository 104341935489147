import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import PlayerIcon from '../PlayerIcon'
import style from "./style.module.css";
import { withTranslation } from 'react-i18next';

const RatingStars = ({ onChange = () => { }, t = _ => _}) => {
  const array = [1, 2, 3, 4, 5];
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [toggleClicked, setToggleCliked] = useState(false)

  const handleClick = (ratingValue) => {
    setRating(ratingValue);
    onChange(ratingValue);
    setToggleCliked(true)
  };

  return (
    <div class={style.RatingStarsContainer} style={{transform : toggleClicked ? 'scale(0.95)' : ''}}>
      {array.map((ratingValue, index) => {
        return (
          <label key={`rating-${ratingValue}`}>
            <input
              type='radio'
              name="rating"
              value={ratingValue}
              onClick={() => (handleClick(ratingValue))}
            />
            <div
              class={style.starContainer}
              onMouseEnter={() => (setHover(ratingValue))}
              onMouseLeave={() => (setHover(null))}
            >
              <PlayerIcon
                titleAccessibility={t(`Star number ${index}`)}
                type={ratingValue <= (hover || rating) ? 'STAR_FILL' : 'STAR_EMPTY'}
                color='var(--main-color)'
              />
            </div>
          </label>
        )
      })}
    </div>
  )
}

export default withTranslation()(RatingStars);


RatingStars.propTypes = {
  onChange: PropTypes.func,
};
