import PropTypes from 'prop-types';
import PlayerIcon from '../PlayerIcon';
import style from "./style.module.css";

const LanguagesLine = ({className = '', subtitleId = null, languageName = '', selected = false, onClick = () => { }, titleAccessibility = ''}) => {
    return (
      <div class={`${style.languagesLineContainer} ${selected ? style.selected : ''} ${className}`} aria-subtitleId={subtitleId} onClick={() => onClick(subtitleId)} tabIndex={0}>
        <p>{(languageName || '').toUpperCase()}</p>
        {selected && <PlayerIcon type='CHECK' color='#02ca5f' titleAccessibility={titleAccessibility} />}
      </div>
    )
};

export default LanguagesLine;

LanguagesLine.propTypes = {
  className: PropTypes.string,
  languageName: PropTypes.string,
  subtitleId: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  titleAccessibility: PropTypes.string,
};
