import { createRef, Component } from 'preact';
import Shaka from 'shaka-player';
import PropTypes from 'prop-types';
import style from './style.module.css';
import { isIosDevice } from "../../utils";
import { createAudioElement, setIosAudioParams } from "../../utils/audio";

class Video extends Component {

    constructor(props) {
        super(props);
        this.ref = createRef();
        this.musicRef = createRef();
        this.player = null;
        this.progressInterval = null;
        this.fadeInterval = null;
        this.previousProgress = null;
        this.isFading = null;
        this.variantsSizes = null;
        this.previousVolume = this.props.initialMute ? 0 : 1;
        this.styleBgd = this.props.backgroundUrl ? { backgroundImage: `url(${this.props.backgroundUrl})` } : {};

        this.onDuration = () => {
            this.props.onDuration(this.ref.current.duration);
        }

        this.onPause = () => {
            let progress = {
              played: Math.max(Math.min(this.ref.current.currentTime / this.ref.current.duration, 1), 0),
              playedSeconds: this.ref.current.currentTime,
              duration: this.ref.current.duration,
            }

            if (this.props.musicUrl) {
                this.musicRef.current.pause();
            }
            clearInterval(this.progressInterval);
            this.props.onPause(progress);
        }

        this.onPlay = () => {
            let progress = {
              played: Math.max(Math.min(this.ref.current.currentTime / this.ref.current.duration, 1), 0),
              playedSeconds: this.ref.current.currentTime,
              duration: this.ref.current.duration,
            }

            if (this.props.musicUrl) {
                this.musicRef.current.play();
            }
            clearInterval(this.progressInterval);
            this.progressInterval = setInterval(() => {
                let progress = {
                    played: Math.max(Math.min(this.ref.current.currentTime / this.ref.current.duration, 1), 0),
                    playedSeconds: this.ref.current.currentTime,
                    duration: this.ref.current.duration,
                }
                progress.isBuffering = (this.previousProgress !== null && this.previousProgress.played === progress.played);
                if (this.ref.current.currentTime > this.ref.current.duration - 1.4 && !this.isFading) {
                    this.audioFade();
                }
                if (this.previousProgress !== null && this.previousProgress.isBuffering !== progress.isBuffering) {
                    this.props.onBuffering(progress.isBuffering);
                }
                if (this.props.musicUrl) {
                    if (progress.isBuffering) {
                        this.musicRef.current.pause();
                    } else {
                        this.musicRef.current.play();
                    }
                }
                this.props.onProgress(progress);
                this.previousProgress = progress;
            }, this.props.progressInterval);

            this.props.onPlay(progress);
        }

        this.onEnded = () => {
            if (this.props.musicUrl) {
                this.musicRef.current.pause();
            }
            clearInterval(this.progressInterval);
            this.props.onProgress({
                played: 1,
                playedSeconds: this.ref.current.duration,
                duration: this.ref.current.duration,
                isBuffering: false,
            });
            this.props.onEnded();
        }
    }

    static defaultProps = {
        url: '',
        backgroundUrl: '',
        musicUrl: '',
        musicVolume: 0,
        type: "DIRECT",
        autoPlay: false,
        progressInterval: 50,
        initialMute: false,
        onProgress: (progress) => { console.log('onProgress', progress); },
        onBuffering: (isBuffering) => { console.log('onBuffer', isBuffering); },
        onPause: () => { console.log('onPause'); },
        onPlay: () => { console.log('onPlay'); },
        onEnded: () => { console.log('onEnded'); },
        onDuration: (duration) => { console.log('onDmration', duration); },
        onPlayingEvent: () => {},
    }

    audioFade() {
        let volume = this.previousVolume;
        const step = volume / 1 / 20; // 1 = (this.ref.current.duration - this.ref.current.currentTime)
        if (volume > 0) {
            this.isFading = true;
            this.fadeInterval = setInterval(() => {
                this.ref.current.volume = volume;
                if (this.props.musicUrl) {
                    this.musicRef.current.volume = this.props.musicVolume * volume;
                }
                volume -= step;
                if (volume <= 0) {
                    clearInterval(this.fadeInterval);
                    setTimeout(() => {
                        this.ref.current.volume = this.previousVolume;
                        if (this.props.musicUrl) {
                            this.musicRef.current.volume = this.props.musicVolume * this.previousVolume;
                        }
                    }, 1000);
                }
            }, 50);
        }
    }

    stopFadeAudio() {
        clearInterval(this.fadeInterval);
        this.ref.current.volume = this.previousVolume;
        if (this.props.musicUrl) {
            this.musicRef.current.volume = this.props.musicVolume * parseFloat(this.previousVolume);
        }
        this.isFading = false;
    }

    render() {
        return (
            <>
                <video
                    ref={this.ref}
                    className={style.player}
                    playsinline
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onSound={this.onSound}
                    onEnded={this.onEnded}
                    onDurationChange={this.onDuration}
                    preload="auto"
                    style={this.styleBgd}
                    autoPlay={this.props.autoPlay}
                />
                {this.props.musicUrl && <audio className={style.audio} volume={this.props.musicVolume} src={isIosDevice() ? null : this.props.musicUrl} ref={this.musicRef} preload="auto" loop />}
            </>
        );
    }

    setMusicOnIos() {
        try {
            // there is an issue with background music sound on ios devices (it's too loud, and we can not hear the voice from video)
            // https://stackoverflow.com/questions/27296391/is-there-any-possibility-to-control-html5-audio-volume-on-ios/54856004#54856004
            if (this.props.musicUrl && isIosDevice()) {
                this.ref.current.parentElement.querySelectorAll('audio').forEach((audio) => audio.remove());
                this.musicRef.current = createAudioElement({
                    className: style.audio,
                    src: this.props.musicUrl,
                    parentElement: this.ref.current.parentElement,
                    onloadeddata: (e) => setIosAudioParams({
                        mediaElement: e.target,
                        volume: this.props.musicVolume,
                        time: this.ref.current.currentTime
                    })
                });
            }
        } catch (e) {
            console.error('Failed to start playing background music on IOS', e.message);
        }
    }

    play() {
        this.ref.current.play();
        if (this.props.musicUrl) {
            this.musicRef.current.play();
        }
        this.stopFadeAudio();
    }

    pause() {
        this.ref.current.pause();
        if (this.props.musicUrl) {
            this.musicRef.current.pause();
        }
        this.stopFadeAudio();
    }

    seekTo(time) {
        this.ref.current.currentTime = time;

        let progress = {
            played: Math.max(Math.min(this.ref.current.currentTime / this.ref.current.duration, 1), 0),
            playedSeconds: this.ref.current.currentTime,
            duration: this.ref.current.duration,
        }

        this.props.onSeek(progress);
        this.stopFadeAudio();
    }

    setVolume(volume) {
        this.ref.current.muted = volume === 0;
        if (this.props.musicUrl) {
            this.musicRef.current.muted = volume === 0;
        }
        this.previousVolume = volume === 0 ? 0 : 1;
    }

    async initShakaPlayer() {
        // Shaka init
        Shaka.polyfill.installAll();
        if (!Shaka.Player.isBrowserSupported()) {
            console.error('Browser not supported!');
            return;
        }

        // Shaka configuration
        this.player = new Shaka.Player(this.ref.current);
        this.player.configure({
            streaming: {
                retryParameters: {
                    timeout: 60000,
                    maxAttempts: 50,
                    baseDelay: 0,
                    backoffFactor: 0,
                    fuzzFactor: 0,
                }
            },
            manifest: {
                hls: {
                    useFullSegmentsForStartTime: true,
                },
            }
        });

        // Error handling
        this.player.addEventListener('error', (event) => {
            console.error('Error code', event.detail.code, 'object', event.detail);
        });
        await this.player.load(this.props.url).catch((error) => {
            console.error('Error code', error.code, 'object', error);
        });
        this.variantsSizes = this.player.getVariantTracks().map((variant) => ({
            maxWidth: variant.width,
            maxHeight: variant.height,
        })).sort((s1, s2) => s1.maxWidth - s2.maxWidth);

        // add resize observer
        const ro = new ResizeObserver((entries) => {
            if (entries.length !== 1) {
                return;
            }

            const cr = entries[0].contentRect;

            this.player.configure({
                streaming: {
                    retryParameters: {
                        timeout: 60000,
                        maxAttempts: 50,
                        baseDelay: 0,
                        backoffFactor: 0,
                        fuzzFactor: 0,
                    },
                },
                restrictions: this.getRestrictionsBySize(cr.width, cr.height),
            });

        });
        ro.observe(this.ref.current);

        // Assign to window to enable debug
        window._shaka = this.player;
    }

    getRestrictionsBySize(width, height) {
        const defaultValue = {
            maxWidth: Infinity,
            maxHeight: Infinity,
        };

        if (!this.variantsSizes) {
            return defaultValue;
        }

        return this.variantsSizes.find((s) => s.maxWidth >= width && s.maxHeight >= height) || defaultValue;
    }

    initDirectPlayer() {
        this.ref.current.src = this.props.url;
    }

    componentDidMount() {
        this.ref.current.muted = this.props.initialMute;
        if (this.props.musicUrl) {
            this.musicRef.current.muted = this.props.initialMute;
        }
        if (this.props.type === 'DASH' || this.props.type === 'HLS') {
            this.initShakaPlayer();
        } else if (this.props.type === 'DIRECT') {
            this.initDirectPlayer();
        }
        this.ref.current.addEventListener('playing', () => {
            this.props.onPlayingEvent();
            this.setMusicOnIos();
        });
    }
}

Video.propTypes = {
    url: PropTypes.string,
    backgroundUrl: PropTypes.string,
    musicUrl: PropTypes.string,
    musicVolume: PropTypes.number,
    type: PropTypes.oneOf(['DASH', 'HLS', 'DIRECT']),
    autoPlay: PropTypes.bool,
    progressInterval: PropTypes.number,
    initialMute: PropTypes.bool,
    onProgress: PropTypes.func,
    onBuffering: PropTypes.func,
    onSeek: PropTypes.func,
    onPause: PropTypes.func,
    onPlay: PropTypes.func,
    onEnded: PropTypes.func,
    onDuration: PropTypes.func,
};

export default Video;
