import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import icons from './icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from "./style.module.css";

const PlayerIcon = ({
  type = '',
  color = '',
  onClick = () => { },
  className = '',
  classNameHover = '',
  titleAccessibility = '',
  showHover = false,
  hoverTitle = '',
  rightPosition = { },
  leftPosition = { },
  isMobile = false,
  disabled = false,
  clickHandle = null,
  tabIndex = 0,
  onKeyDown = () => {},
}) => {
  const [hover, setHover] = useState(false)

  const icon = icons[type];
  if (!icon) return null;

  const { Icon } = icon;

  const showHoverToogle = () => {
    setHover(true);
  }

  const dontShowHoverToogle = () => {
    setHover(false);
  }

  if (!clickHandle) {
    return (<span title={titleAccessibility} aria-live="assertive">
      {showHover && hoverTitle && !isMobile && (
        <div
          class={`${style.hoverIcon} ${hover ? '' : style.hide} ${classNameHover}`}
          style={{right: rightPosition, left: leftPosition}}>
            {hoverTitle}
        </div>
      )}
      <FontAwesomeIcon
        icon={Icon}
        className={`${style.icon} ${className} ${disabled ? style.disabled : ''}`}
        color={disabled ? 'gray' : color}
        onClick={disabled ? () => {} : onClick}
        onMouseEnter={showHoverToogle}
        onMouseLeave={dontShowHoverToogle}
        tabIndex={tabIndex}
      />
    </span>);
  }

  return (
    <button title={titleAccessibility} onClick={clickHandle} aria-label={titleAccessibility} aria-type={type} aria-live="assertive" className={style.button} tabIndex={tabIndex} onKeyDown={onKeyDown}>
      {showHover && hoverTitle && !isMobile && (
        <div
          class={`${style.hoverIcon} ${hover ? '' : style.hide} ${classNameHover}`}
          style={{right: rightPosition, left: leftPosition}}>
            {hoverTitle}
        </div>
      )}
      <FontAwesomeIcon
        icon={Icon}
        className={`${style.icon} ${className} ${disabled ? style.disabled : ''}`}
        color={disabled ? 'gray' : color}
        onMouseEnter={showHoverToogle}
        onMouseLeave={dontShowHoverToogle}
      />
    </button>
  );
};

export default PlayerIcon;

PlayerIcon.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classNameHover: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  titleAccessibility: PropTypes.string,
  showHover: PropTypes.bool,
  hoverTitle: PropTypes.string,
  rightPosition: PropTypes.number,
  leftPosition: PropTypes.number,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};
