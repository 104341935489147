
const calcStoryboardPosition = (nb, nbImagePerRow, nbImagePerColumn, width, height, offsetX = 0, offsetY = 0) => {
    const x = Math.floor(nb % nbImagePerRow);
    const y = Math.floor(nb / nbImagePerColumn);
    return `-${x * width + offsetX}px -${y * height + offsetY}px`;
}

export const getBackgroundStyleForCard = (time, highStoryboards) => {
    const storyboard = highStoryboards.find(e => e.timeStart <= time && time < e.timeEnd);
    
    if (storyboard) {
        const storyboardRect = { width: storyboard.width * storyboard.imagePerRow, height: storyboard.height * storyboard.imagePerColumn };
        let height;
        let width;
        if (storyboard.width > storyboard.height) {
            height = 46;
            width = height * storyboardRect.width / storyboardRect.height;
        } else {
            width = 46;
            height = width * storyboardRect.height / storyboardRect.width;
        }
        const containerWidth = width * storyboard.imagePerRow;
        const containerHeight = height * storyboard.imagePerColumn;
        return {
            backgroundImage: `url(${storyboard.path})`,
            backgroundSize: `${containerWidth}px ${containerHeight}px`,
            backgroundPosition: calcStoryboardPosition(time % (storyboard.imagePerRow * storyboard.imagePerColumn), storyboard.imagePerRow, storyboard.imagePerColumn, width, height, (width - 46) / 2, (height - 46) / 2),
        }
    }
    return {};
}

export const getBackgroundStyleForForegroundImage = (storyboard, containerRect) => {
    const storyboardRatio = storyboard.width / storyboard.height;
    const containerRatio = containerRect.width / containerRect.height;
    const height = (() => {
        if (storyboardRatio < containerRatio) {
            return containerRect.height;
        } 
            return containerRect.width / storyboardRatio;
        
    })();
    const width = (() => {
        if (storyboardRatio < containerRatio) {
            return containerRect.height * storyboardRatio;
        } 
            return containerRect.width;
        
    })();
    const containerWidth = width * storyboard.imagePerRow;
    const containerHeight = height * storyboard.imagePerColumn;
    return {
        backgroundImage: `url(${storyboard.path})`,
        backgroundSize: `${containerWidth}px ${containerHeight}px`,
        backgroundPosition: calcStoryboardPosition(storyboard.time % (storyboard.imagePerRow * storyboard.imagePerColumn), storyboard.imagePerRow, storyboard.imagePerColumn, width, height),
        width,
        height,
        top: (containerRect.height - height) / 2,
        left: (containerRect.width - width) / 2,
    }
}

const getBackgroundStyleForPreviewQuality = (time, storyboard) => {
    const storyboardRect = { width: storyboard.width * storyboard.imagePerRow, height: storyboard.height * storyboard.imagePerColumn };
    const height = 90;
    const width = height * storyboardRect.width / storyboardRect.height;
    const containerWidth = width * storyboard.imagePerRow;
    const containerHeight = height * storyboard.imagePerColumn;
    return {
        backgroundImage:  `url(${storyboard.path})`,
        backgroundPosition: calcStoryboardPosition(time % (storyboard.imagePerRow * storyboard.imagePerColumn), storyboard.imagePerRow, storyboard.imagePerColumn, width, height),
        backgroundSize: `${containerWidth}px ${containerHeight}px`,
        width,
        height,
    }
}

export const getBackgroundStyleForPreview = (time, lowStoryboards, highStoryboards) => {
    const lowStoryboard = lowStoryboards.find(e => e.timeStart <= time && time < e.timeEnd);
    const highStoryboard = highStoryboards.find(e => e.timeStart <= time && time < e.timeEnd);

    const result = {};

    if (lowStoryboard) {
        result.low = getBackgroundStyleForPreviewQuality(time, lowStoryboard);
        result.lowPath = lowStoryboard.path;
    }

    if (highStoryboard) {
        result.high = getBackgroundStyleForPreviewQuality(time, highStoryboard);

        if (lowStoryboard) {
            Object.keys(result.high).filter(key => key.startsWith('background')).forEach(key => {
                result.high[key] += `, ${result.low[key]}`;
            });
        }

        result.highPath = highStoryboard.path;
    }

    return result;
}