import PropTypes from 'prop-types';
import style from "./style.module.css";

const Loader = ({ show = true }) => {
    return (
        <div className={`${style.loader} ${show ? '' : style.hide}`}>
            <svg className={style.circular} viewBox="25 25 50 50">
                <circle className={style.path} cx="50" cy="50" r="20" fill="none" strokeWidth="2.5" strokeMiterlimit="10" />
            </svg>
        </div>
    );
};

Loader.propTypes = {
    show: PropTypes.bool
}

export default Loader;
