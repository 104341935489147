import { CDN_CACHE_DELAY, URL_API } from "./config";

import ProjectFragment from './graphql/projectFragment.graphql';
import SolutionFragment from './graphql/solutionFragment.graphql';
import getPlayerDataWithPlayerId from './graphql/getPlayerDataWithPlayerId.graphql';
import getPlayerDataWithoutPlayerId from './graphql/getPlayerDataWithoutPlayerId.graphql';

const boolish = (value) => (value === '1' || value.toLowerCase() === 'true');

const tinyGetGraphql = async (query = [], variables, cacheDuration = 0) => {
    const lightBody = query.map(e => e.loc.source.body.split('\n').map(line => line.trim()).join(' ')).join(' ')
    const result = await fetch(`${URL_API}graphql?query=${encodeURIComponent(lightBody)}&variables=${encodeURIComponent(JSON.stringify(variables))}${cacheDuration ? `&cacheduration=${cacheDuration}` : ''}`, {
        headers: {
            'content-type': 'application/json; charset=utf-8',
            'apollographql-client-name': 'player-front',
            'apollographql-client-version': "3.1",
        }
    }).then(r => r.json());
    return result.data;
}

// If you edit this query, you also have to patch the router-ssr project
async function getApiData(projectId, playerId = null) {
    if (playerId) {
        return tinyGetGraphql([ProjectFragment, SolutionFragment, getPlayerDataWithPlayerId], { projectId, playerId }, getCacheDuration())
    }
    return tinyGetGraphql([ProjectFragment, SolutionFragment, getPlayerDataWithoutPlayerId], { projectId }, getCacheDuration())
}

export const getData = async (projectId, playerId = null) => {
    if (!projectId) {
        return null
    }
    if (window.teester && window.teester.data) {
        return window.teester.data;
    }
    const data = await getApiData(projectId, playerId);
    return data;
}

export const getUrlArguments = () => {
    // Get parameters both from query string and hash fragment
    const params = {
        ...Object.fromEntries(new URLSearchParams(window.location.search.slice(1)).entries()),
        ...Object.fromEntries(new URLSearchParams(window.location.hash.slice(1)).entries()),
    };

    // Boolish some args
    return Object.entries(params).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: typeof acc[key] === 'boolean' ? boolish(value) : (value || null),
    }), {
        allow_share: true,
        allow_rate: true,
        allow_ambassador: true,
        allow_buy: false,
        autoplay: false,
        enable_cache: true,
        id: null,
        key: null,
        link: null,
        music: null,
        music_volume: null,
        origin: null,
        session: null,
        tracking: true,
        tracking_event: 'PLAYER_PLAY',
        vsg_id: null,
        get computed_origin() {
            return this.origin || window.location.href;
        },
    });
}

export const getCacheDuration = () => (getUrlArguments()?.enable_cache) ? CDN_CACHE_DELAY : 0;
