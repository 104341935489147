import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PlayerIcon from '../PlayerIcon';
import style from "./style.module.css";

const AddToBasketButton = ({
      onClick = () => { },
      show = false, t = _ => _,
      stateButton = true
    }) => {

  return (
    <>
      <div class={`${style.buttonContainer} ${stateButton ? style.addButton : style.validateButton} ${show ? '' : style.hide}`}>
        <PlayerIcon type={'ADD_TO_BASKET_LIGHT'} color='#fff' clickHandle={onClick} titleAccessibility={t('Go to product page')} />
      </div>
    </>
  );
};

export default withTranslation()(AddToBasketButton);

AddToBasketButton.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
  t: PropTypes.func,
  typeIcon: PropTypes.string,
  popupStatus: PropTypes.bool,
  stateButton: PropTypes.bool,
};
