import PropTypes from 'prop-types';
import { Component } from 'preact';
import Subtitles from '../Subtitles';
import style from "./style.module.css";
import { loadSubtitles } from '../../subtitlesLoader';
import { getCacheDuration } from '../../api';

class SubtitlesContainer extends Component {

    constructor(props) {
        super(props);
        this.subtitles = {}
    }

    static defaultProps = {
        url: '',
    }

    async initSubtitles(url) {
        if (this.subtitles[url] || !url) {
            return false;
        }

        this.subtitles[url] = await loadSubtitles(`${url}${getCacheDuration() ? `?cacheduration=${getCacheDuration()}` : ''}`);
        return true;
    }

    render() {
        const toShow = (this.subtitles[this.props.url] || [])
            .filter(cue => cue.startTime <= this.props.currentTime && cue.endTime > this.props.currentTime)
            .map(cue => cue.text).reduce((acc, texts) => [...acc, ...texts], []);
        const text = toShow.join('\n');
        if (!text) {
            return null;
        }
        return (
            <div class={`${style.subtitlesContainer} ${this.props.show ? '' : style.bottom} `}>
                <Subtitles text={text} />
            </div>
        );
    }

    componentDidMount() {
        this.initSubtitles(this.props.url);
    }

    componentWillUpdate(nextProps) {
        this.initSubtitles(nextProps.url);
    }
}

SubtitlesContainer.propTypes = {
    url: PropTypes.string,
    currentTime: PropTypes.number,
};

export default SubtitlesContainer;
