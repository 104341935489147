import { parser } from 'simple-subtitle-parser';

let subtitlesPromises = {};

const getSubtitleContent = async (url) => {
    if (subtitlesPromises[url] || !url) {
        return null;
    }
    const subs = await (await fetch(url)).text();
    if (!subs) {
        return null;
    }

    try {
        const cues = await parser(url.toLowerCase().indexOf('.srt') !== -1 ? 'SRT' : 'WEBVTT', subs);
        return cues.map(cue => ({ ...cue, startTime: cue.startTime.totals.inSeconds, endTime: cue.endTime.totals.inSeconds }));
    } catch (err) {
        return [];
    }
}

export const loadSubtitles = (url) => {
    if (subtitlesPromises[url]) {
        return subtitlesPromises[url];
    }
    subtitlesPromises[url] = getSubtitleContent(url);
    return subtitlesPromises[url];
}