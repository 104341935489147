import PropTypes from 'prop-types';
import style from "./style.module.css";

const Button = ({title='', onClick = () => { }, className = '', disabled = false}) => {

  return (
    <button class={`${style.buttonContainer} ${disabled ? style.disabled : ''} ${className}`} onClick={onClick} title={title}>
      <p>{title}</p>
    </button>
  );
};

export default Button;

Button.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
