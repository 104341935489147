import PropTypes from 'prop-types';
import LogoSkeepers from './skeepersLogoWhite.svg';
import style from './style.module.css';
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'preact/hooks';

const LogoPlayer = ({
  show = true,
  color = '',
  logoUrl = '',
  customLogoUrl = '',
  customLogoAltText = '',
  t = (_) => _,
  isVertical = false,
}) => {
  const [altText, setAltText] = useState('');

  useEffect(() => {
    const getAltText = () => {
      if (customLogoAltText) {
        return customLogoAltText;
      }
      if (customLogoUrl) {
        return t('Company logo that redirects to the company\'s ambassador platform');
      }
      return t('Skeepers Logo that redirects to the company\'s ambassador platform');
    };

    setAltText(getAltText());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customLogoAltText, customLogoUrl]);

  

  return (
    <a
      className={`${style.logoPlayer} ${!customLogoUrl ? style.noCustomLogo : ''} ${show ? '' : isVertical ? style.hideVertical : style.hide} ${
        !logoUrl ? style.noLink : ''
      }`}
      style={{ fill: color }}
      href={logoUrl}
      target='_blank'
      rel='noreferrer'
      aria-label={altText}
    >
      {customLogoUrl && <img src={customLogoUrl} className={style.logo} />}
      {!customLogoUrl && <LogoSkeepers />}
    </a>
  );
};

export default withTranslation()(LogoPlayer);

LogoPlayer.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
  logoUrl: PropTypes.string,
  isVertical: PropTypes.bool,
};
