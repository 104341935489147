import { v4 as uuidv4 } from 'uuid';
import { URL_IMAGE } from './config';
import { Mixpanel } from './mixpanel';

let hasLoggedStorageWarning = false;

const padWithZero = (n) => String(n).padStart(2, '0');

export const durationInMinutes = (time) => {
    const hours = Math.floor(time / 3600);
    const paddedHours = hours ? `${padWithZero(hours)}:` : '';
    const paddedMins = padWithZero(Math.floor((time % 3600) / 60));
    const paddedSeconds = padWithZero(Math.floor(time % 60));
    return `${paddedHours}${paddedMins}:${paddedSeconds}`;
};

export const floorValue = (v) => Math.floor(v * 1000) / 1000;

export const onFullScreenEnter = (div) => {
    try {
        if (div.requestFullscreen) {
            Mixpanel.track('CV_PLAYER_FULLSCREEN_ENTER', {
                openedAt: new Date(),
                userAgent: navigator.userAgent,
            })
            div.requestFullscreen();
            return true;
        }
    } catch (e) { }
    return false;
};

export const onFullScreenExit = () => {
    try {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            return true;
        }
    } catch (e) { }
    return false;
};

let webp = null;

const detectWebpSupport = () => {
    const elem = document.createElement('canvas');
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!(elem.getContext && elem.getContext('2d'))) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
}

export const canUseWebp = () => {
    if (webp === null) {
        webp = detectWebpSupport();
    }
    return webp;
};

export const getPictureLink = (path = '', args = {}, alpha = false) => {
    if (!path) {
        return null;
    }
    const params = Object.keys(args).map(e => `${e}=${encodeURIComponent(args[e])}`).join('&');
    return `${URL_IMAGE}?url=${encodeURIComponent(path)}&output=${canUseWebp() ? 'webp' : alpha ? 'png' : 'jpg'}${params.length ? '&' : ''}${params}`;
}

export const getFullLanguageName = (code) => {
    // Based on https://en.wikipedia.org/wiki/List_of_language_names
    const langs = {
        fr: 'Français',
        en: 'English',
        da: 'Dansk',
        de: 'Deutsch',
        es: 'Español',
        fi: 'Suomi',
        nl: 'Nederlands',
        'nl-be': 'Nederlands (België)',
        it: 'Italiano',
        no: 'Norsk',
        pt: 'Português',
        sv: 'Svenska',
        ru: 'РУССКИЙ',
        cn: '普通話',
        ko: '한국어',
        jp: '日本語',
        ae: 'العربية',
    }
    return langs[code.toLowerCase()] || code.toLowerCase();
}

// Source: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isIosDevice = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

// Try to detect Safari or device
export const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return (ua.includes('safari') && !ua.includes('chrome') && !ua.includes('android')) || (navigator.vendor && navigator.vendor.toLowerCase().includes('apple'));
}

export const isBrowserCookieScopeLimited = () => {
    return isIosDevice() || isSafari();
}

const warnStorageNotAllowed = () => {
    if (!hasLoggedStorageWarning) {
        hasLoggedStorageWarning = true;
        console.warn('[Skeepers] Please be aware that as you have constraints on cookies access/creation on your browser parameters, your video player experience might be less good.');
    }
}

export const getFromLocalStorage = (key) => {
    try {
        return localStorage.getItem(key) || null;
    } catch {
        warnStorageNotAllowed();
        return null;
    }
}

export const setToLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch {
        warnStorageNotAllowed();
    }
}


export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch {
        warnStorageNotAllowed();
    }
}

export const getLocalSessionId = () => {
    const currentSessionId = getFromLocalStorage('teester_session');
    if (currentSessionId) {
        return currentSessionId;
    }
    const uuid = uuidv4();
    setToLocalStorage('teester_session', uuid);
    return uuid;
}

// for screen-readers after Play button from thumbnail was hidden or when replay button was pressed
// we're moving focus to "pause" button on the timeline
export const focusOnPauseButton = (e, callback = () => {}) => {
    e.stopPropagation();

    if (e.key === ' ' || e.key === 'Enter') {
        document.getElementById('video-navigation-buttons')?.children?.[1]?.focus();
        callback();
    }
}

export const PLAYER_MODE = {
    default: 'DEFAULT',
    preview: 'PREVIEW'
}

export const isValidMode = (mode) => Object.values(PLAYER_MODE).includes(mode);
