import {useState} from 'preact/hooks';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import ActionButton from '../ActionButton';
import PlayerIcon from '../PlayerIcon';
import EndModalInteraction from '../EndModalInteraction';
import ModalParticipate from '../ModalParticipate';
import ModalRate from '../ModalRate';
import ModalShare from '../ModalShare';
import ModalThanks from '../ModalThanks';
import style from "./style.module.css";
import { focusOnPauseButton } from "../../utils";

export const EndMenu = ({
  showEndMenu = false,
  joinUrl = '',
  productPageUrl = '',
  shareUrl = '',
  t = _ => _,
  companyName = '',
  lastFrameUrl = '',
  allowBuy = true,
  allowRating = true,
  allowShare = true,
  allowJoinCommunity = true,
  isMobile = false,

  onRefresh = () => { },
  onClickBasketButton = () => { },
  onRate = () => { },
  onShare = () => { },
  onCommunityJoin = () => { },
}) => {

  const [showEndModalInteraction, setShowEndModalInteraction] = useState(false);
  const [endmodaInteractionAnimated, setEndmodaInteractionAnimated] = useState(false);
  const [idEndModalInteraction, setIdEndModalInteraction] = useState('');
  const [titleEndModalInteraction, setTitleEndModalInteraction] = useState('');
  const [typeIconEndModalInteraction, setTypeIconEndModalInteraction] = useState('');
  const [thanksModalRateState, setThanksModalRateState] = useState('');
  const [thanksModalShareState, setThanksModalShareState] = useState('');

  const buttons = [
    {
      id: 'buyButton',
      title: t('Buy'),
      typeIcon: 'ADD_TO_BASKET_LIGHT',
      color: '#fff',
      onClick: () => handleProductClick(),
      titleAccessibility: t('Go to product page'),
      canBeDisplay: allowBuy && productPageUrl,
      size: 'lg'
    },
    {
      id: 'rateButton',
      title: t('Rate'),
      typeIcon: 'STAR_EMPTY',
      color: '#fff',
      onClick: () => {handleModalInteraction(buttons[1]);},
      titleAccessibility: t('Click on this button to rate the video'),
      canBeDisplay: allowRating,
      size: 'lg'
    },
    {
      id: 'shareButton',
      title: t('Share'),
      typeIcon: 'SHARE_EMPTY',
      color: '#fff',
      onClick: () => {share();},
      titleAccessibility: t('Click on this button to share the video'),
      canBeDisplay: allowShare,
      size: 'lg'
    },
    {
      id: 'participateButton',
      title: t('Participate'),
      typeIcon: 'CROWN_EMPTY',
      color: '#fff',
      onClick: () => {handleModalInteraction(buttons[3]);},
      titleAccessibility: t('Click on this button to join the community'),
      canBeDisplay: allowJoinCommunity && joinUrl,
      size: 'lg'
    }
  ];

  /** Display the EndmodalInteraction modal with the right props of its button **/
  const handleModalInteraction = (button) => {
    setIdEndModalInteraction(button.id);
    setTitleEndModalInteraction(button.title);
    setTypeIconEndModalInteraction(button.typeIcon);
    setShowEndModalInteraction(true);
    setTimeout(() => {
      setEndmodaInteractionAnimated(true);
    }, 100);
  };

  const closeEndModalInteraction = () => {
    setEndmodaInteractionAnimated(false);
    setTimeout(() => {
      setShowEndModalInteraction(false);
    }, 500);
  };

  const handleThanksRate = (rate) => {
    setThanksModalRateState(buttons[1].id);
    onRate(rate);
  };

  const handleThankShare = () => {
    setThanksModalShareState(buttons[2].id);
    onShare();
    setTimeout(() => {
      setThanksModalShareState('');
    }, 2000);
  };

  const handleCommunityClick = () => {
    onCommunityJoin();
    window.open(joinUrl, '_blank');
  };

  const handleProductClick = () => {
    onClickBasketButton();
    window.open(productPageUrl, '_blank');
  };

  const replayEndModalInteraction = () => {
    setShowEndModalInteraction(false);
    onRefresh(0);
  };

  const share = () => {
    handleModalInteraction(buttons[2]);
  };

  const background = lastFrameUrl;

  return (
    <>
      <div class={`${style.fullContainer} ${showEndMenu ? style.animated : ''} `} style={{display: showEndMenu ? 'flex' : 'none', ...(background ? {backgroundImage: `url(${background})`} : {})}}>
        <div class={`${style.EndMenuContainer}`}>
          {buttons.map(button => (
            button.canBeDisplay && <ActionButton titleButton={button.title} typeIcon={button.typeIcon} color={button.color} onClick={button.onClick} titleAccessibility={button.titleAccessibility} styleProps={button.style} />
          ))}
        </div>
      </div>
      <div class={`${style.refreshButton} ${showEndMenu ? style.animated : ''} `} style={{display: showEndMenu ? 'block' : 'none'}}>
        <PlayerIcon
          type='REFRESH'
          color="var(--main-color)"
          showHover={true}
          hoverTitle={t('Reload')}
          titleAccessibility={t('Reload')}
          rightPosition={0}
          classNameHover={style.hoverIconRefresh}
          isMobile={isMobile}
          clickHandle={replayEndModalInteraction}
          onKeyDown={(e) => focusOnPauseButton(e, replayEndModalInteraction)}
        />
      </div>
      <EndModalInteraction showEndModalInteraction={showEndModalInteraction} endmodaInteractionAnimated={endmodaInteractionAnimated} title={titleEndModalInteraction} type={typeIconEndModalInteraction} onClose={closeEndModalInteraction} onRefresh={replayEndModalInteraction} lastFrameUrl={lastFrameUrl}>
        {idEndModalInteraction === buttons[1].id && <ModalRate onClick={handleThanksRate} />}
        {idEndModalInteraction === buttons[2].id && <ModalShare onClick={handleThankShare} shareUrl={shareUrl} />}
        {idEndModalInteraction === buttons[3].id && <ModalParticipate companyName={companyName} onClick={handleCommunityClick} />}
        {idEndModalInteraction === buttons[1].id && thanksModalRateState === buttons[1].id && <ModalThanks title={t('Thanks for your feedback!')} text={t('Your feedback has been saved')} />}
        {idEndModalInteraction === buttons[2].id && thanksModalShareState === buttons[2].id && <ModalThanks title={t('Thanks for sharing this video!')} text={t('The video has been shared')} />}
      </EndModalInteraction>
    </>
  );
};

export default withTranslation()(EndMenu);

EndMenu.propTypes = {
  showEndMenu: PropTypes.bool,
  onRefresh: PropTypes.func,
  t: PropTypes.func,
  companyName: PropTypes.string,
  lastFrameUrl: PropTypes.string,
  allowBuy: PropTypes.bool,
  allowRating: PropTypes.bool,
  allowShare: PropTypes.bool,
  allowJoinCommunity: PropTypes.bool,
  onClickBasketButton: PropTypes.func,
  typeIcon: PropTypes.string,
  onRate: PropTypes.func,
  onShare: PropTypes.func,
  onCommunityJoin: PropTypes.func,
  isMobile: PropTypes.bool
};
