import { useState, useEffect } from 'preact/hooks';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import PlayerIcon from "../PlayerIcon";
import style from "./style.module.css";

const TapToUnmute = ({ t = v => v, onClick = () => { } }) => {
  const [hiddingMessage, setHiddingMessage] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setHiddingMessage(true)
    }, 5000);
  })

  return (
    <div class={style.container}>
      <div class={`${style.generals} ${style.buttonContainer}`}>
        <div class={`${style.textInfomation} ${hiddingMessage ? style.textInfomationHidden : ''}`}>
          <p>{t('Tap to unmute')}</p>
        </div>
        <PlayerIcon type={'SOUND_OFF'} clickHandle={onClick} titleAccessibility={t('Tap to unmute')} className={style.iconSound} />
      </div>
    </div>
  );
};

TapToUnmute.propTypes = {
  t: PropTypes.func,
  onClick: PropTypes.func,
};

export default withTranslation()(TapToUnmute);
