import { useEffect } from 'react';
import { canUseWebp } from '../../utils';

export default ({ title = '', subtitle = '', image, onPreviousTrack = () => { }, onNextTrack = () => { }, onPlay = () => { }, onPause = () => { } }) => {
    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new window.MediaMetadata({
                title,
                artist: subtitle,
                ...(image ? {
                    artwork: [{
                        src: image,
                        sizes: '500x500',
                        type: canUseWebp() ? 'image/webp' : 'image/jpeg'
                    }]
                } : {}),
            });

            if (onPreviousTrack) {
                navigator.mediaSession.setActionHandler('previoustrack', onPreviousTrack);
            }
            if (onNextTrack) {
                navigator.mediaSession.setActionHandler('nexttrack', onNextTrack);
            }
            navigator.mediaSession.setActionHandler('play', onPlay);
            navigator.mediaSession.setActionHandler('pause', onPause);
        }
    });
    return null;
};