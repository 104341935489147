import { useState, useEffect, useRef } from "preact/hooks";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import PlayerIcon from "../PlayerIcon";
import style from "./style.module.css";
import { focusOnPauseButton } from "../../utils";

const Thumbnail = ({
  thumbnailText1 = '',
  thumbnailText2 = '',
  onClick,
  onMouseVisible = () => {},
  onMouseInvisible = () => {},
  showVideoPreview = false,
  firstFrameUrl = '',
  thumbnailToogle = true,
  hidden = true,
  introsVideo = null,
  isVertical = false,
  t,
}) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);
  const videoRef = useRef(null);

  //Allows resetting to 16:9.
  const resize = () => {
    const height = ref ? ref.current.clientHeight : 0;
    const width = ref ? ref.current.clientWidth : 0;
    const calcHeight = (height * 16) / 9;
    const calcWidth = (width * 9) / 16;

    if (calcHeight > width) {
      setSize({ width: calcHeight, height });
    } else {
      setSize({ width, height: calcWidth });
    }
  };

  //Allows resize video according to the resize window.
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  let introUrl = null;

  if (introsVideo && videoRef.current) {
    const codec = 'H264';

    const resolutions = introsVideo
      .filter((intro) => intro.codec === codec)
      .map((intro) => intro.resolution)
      .sort((e1, e2) => e1 > e2);

    let resolution = resolutions[0];
    for (let i = 0; i < resolutions.length && resolution <= size.height; i++) {
      resolution = resolutions[i];
    }

    introUrl = introsVideo.find((intro) => intro.codec === codec && intro.resolution === resolution).path;
  }

  return (
    <>
      <div
        ref={ref}
        class={`${style.thumbnailContainerDesktop} ${thumbnailToogle ? null : style.hideThumbnailContainerDesktop}`}
        style={{ display: hidden ? 'block' : 'none' }}
        {...(onClick? { onClick } : {})}
      >
        {onClick && (
            <div
              class={style.playButtonContainerFull}
              onMouseOver={onMouseVisible}
              onMouseOut={onMouseInvisible}
            >
              <button class={style.playButton} aria-label={t('Play button')} onKeyDown={focusOnPauseButton}>
                <PlayerIcon type="PLAY" color="black" titleAccessibility={t('Play button')} />
              </button>
            </div>
        )}
        <div class={style.titleContainer}>
          {thumbnailText1 && (
            <div className={`${style.thumbnailTextContainer} ${style.thumbnailTopTextContainer}`}>
              <h1 className={`${style.thumbnailText} ${style.thumbnailTopText}`}>{thumbnailText1}</h1>
            </div>
          )}
          {thumbnailText2 && (
            <div className={`${style.thumbnailTextContainer} ${style.thumbnailBottomTextContainer}`}>
              <h2 className={`${style.thumbnailText} ${style.thumbnailBottomText}`}>{thumbnailText2}</h2>
            </div>
          )}
        </div>
      </div>
      <div class={`${style.backgroundImageContainer}`} style={{ display: hidden ? 'block' : 'none' }}>
        <div
          class={`${style.backgroundImage} ${thumbnailToogle ? '' : style.hideByOpacity}`}
          style={{
            backgroundImage: `url(${firstFrameUrl})`,
            opacity: showVideoPreview && introUrl ? '0' : '1',
          }}
        />
        {introsVideo && (
          <video
            ref={videoRef}
            playsinline
            autostart
            autoPlay
            loop
            muted
            src={introUrl}
            type='video/mp4'
            class={`${style.video} ${thumbnailToogle ? null : style.hideByOpacity}`}
            style={isVertical ? { maxHeight: '100vh' } : { height: `${size.height}px`, width: `${size.width}px` }}
          />
        )}
      </div>
    </>
  );
};

export default withTranslation()(Thumbnail);

Thumbnail.propTypes = {
  thumbnailText1: PropTypes.string,
  thumbnailText2: PropTypes.string,
  onClick: PropTypes.func,
  thumbnailToogle: PropTypes.bool,
  hidden: PropTypes.bool,
  introsVideo: PropTypes.array,
  showVideoPreview: PropTypes.bool,
};
