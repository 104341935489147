/**
 * Creates GainNode for media element that provides a way to control its volume on IOS
 * @param {HTMLMediaElement} mediaElement - HTMLMediaElement
 * @param {number} [time=0] - time to start playing music from
 * @param {number} [volume=0.5]  - volume of the media element (0...1)
 */
export const setIosAudioParams = ({ mediaElement, time = 0, volume = 0.5 }) => {
    const audioCtx = new AudioContext();
    const source = new MediaElementAudioSourceNode(audioCtx, {
        mediaElement,
    });
    const gainNode = new GainNode(audioCtx);
    source.connect(gainNode);
    gainNode.connect(audioCtx.destination);
    gainNode.gain.value = volume;
    mediaElement.currentTime = mediaElement.duration > 0 ? (time % mediaElement.duration) : 0;
}

/**
 * Creates HTMLAudioElement with given props
 * @param {string} className
 * @param {string} src
 * @param {HTMLElement} parentElement
 * @param {string} preload
 * @param {boolean} loop
 * @param {string} crossOrigin
 * @param {function} onloadeddata
 * @returns {HTMLAudioElement}
 */
export const createAudioElement = ({ className, src, parentElement, preload = 'auto', loop = true, crossOrigin = 'anonymous', onloadeddata }) => {
    const audioElement = document.createElement('audio');
    audioElement.className = className;
    audioElement.src = src;
    audioElement.preload = preload;
    audioElement.loop = loop;
    audioElement.crossOrigin = crossOrigin;
    audioElement.onloadeddata = onloadeddata;
    if (parentElement) {
        parentElement.appendChild(audioElement);
    }
    return audioElement;
}
