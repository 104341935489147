import PropTypes from 'prop-types';
import style from "./style.module.css";

export const Subtitles = ({ text = '' }) => {
  return (
    <div class={style.subtitlesArea}>
      <span class={style.subtitles}>
        {text}
      </span>
    </div>
  );
};

export default Subtitles;

Subtitles.propTypes = {
  text: PropTypes.string,
};
