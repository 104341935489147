// Don't remove the next line. Needed by the test to render virtual dom
import { h } from 'preact';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PlayerIcon from '../PlayerIcon';
import { ZENDESK_EN, ZENDESK_FR, ENABLE_REPORT_LINK } from '../../config';
import { getLanguage } from '../../i18n';

import style from './style.module.css';

export const LegalBanner = ({
    releasedAt,
    testedAt,
    className = '',
    show = true,
    onClick = () => { },
    reportLink = '',
    t
}) => {
    const zendeskLink = getLanguage() === 'fr' ? ZENDESK_FR : ZENDESK_EN;

    return (
        <section class={`${style.root} ${className} ${show ? '' : style.hide}`} onClick={onClick} data-testid='legal-banner-section'>
            <PlayerIcon type='INFO' />
            <p className={style.textContainer} data-testid='legal-banner-text'>
                <span data-testid='legal-banner-publish'>
                    {t('Published the {{publishedDate}} - Tested the {{ testedDate }}', { publishedDate: new Date(releasedAt * 1000)?.toLocaleDateString(getLanguage()), testedDate: new Date(testedAt * 1000)?.toLocaleDateString(getLanguage()) })}
                </span>
                <div class={style.legalBannerLinks}>
                    {
                        show && <a className={style.link} href={zendeskLink} target='_blank' rel='noreferrer' data-testid='legal-banner-zendesk'>
                            {t('Legal information')} <PlayerIcon type='EXTERNAL_LINK' className='inline-link' />
                        </a>
                    }
                    {
                        ENABLE_REPORT_LINK && show && <a className={style.link} href={reportLink} target='_blank' rel='noreferrer' data-testid='legal-banner-report'>
                            {t('Report')} <PlayerIcon type='COMMENT_EXCLAMATION' className='inline-link' />
                        </a>
                    }
                </div>
            </p>
        </section>
    );
};
export default withTranslation()(LegalBanner);

LegalBanner.propTypes = {
    releasedAt: PropTypes.number,
    testedAt: PropTypes.number,
    className: PropTypes.string,
    show: PropTypes.bool,
    onClick: PropTypes.func,
    reportLink: PropTypes.string,
    t: PropTypes.func,
};
