import PropTypes from 'prop-types';
import PlayerIcon from '../PlayerIcon'
import style from "./style.module.css";
import { withTranslation } from 'react-i18next';

const EndModalInteraction = ({
  type = 'STAR_EMPTY',
  title = '',
  children,
  showEndModalInteraction = false,
  endmodaInteractionAnimated = true ,
  onClose = () => { },
  lastFrameUrl = '',
  t = _ => _,
}) => {
  return (
    <div class={`${style.endModalInteractionFullContainer}`} style={{display: showEndModalInteraction ? 'flex' : 'none'}}>
      <div class={`${style.endModalInteractionContainer} ${endmodaInteractionAnimated ? style.endModalInteractionContainerVisible : ``}`} style={{backgroundImage: `url(${lastFrameUrl})`}} onClick={onClose} />
      <div class={`${style.modalInteraction} ${endmodaInteractionAnimated ? style.animated : ''}`}>
        <div class={style.leftColumn}>
          <div class={style.iconContainer}>
              <PlayerIcon type={type} color='white' />
          </div>
          <div class={style.titleContainer}>
            <h1>{title}</h1>
          </div>
        </div>
        <div class={style.rightColumn}>
          {children}
          <span id={style.crossIcon}>
            <PlayerIcon
              type='CROSS'
              titleAccessibility={t('Close popup')}
              clickHandle={onClose}
              color='var(--main-color)'
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(EndModalInteraction);

EndModalInteraction.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  showEndMenu: PropTypes.bool,
  onClose: PropTypes.func,
  lastFrameUrl: PropTypes.string
};
