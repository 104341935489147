import { h } from 'preact';

import PropTypes from 'prop-types';
import Card from '../Card';
import LegalBanner from '../LegalBanner';
import style from "./style.module.css";

export const CardsList = ({
  releasedAt,
  testedAt,
  show = true,
  showLegalBanner,
  cards = [],
  onClick = () => { },
  currentTime = 0,
  thumbnailToogle = false,
  highStoryboard = [],
  reportLink = "",
}) => {
  const currentCard = cards.sort((a, b) => (b.position - a.position)).find(card => card.position <= currentTime && card.position + 4 > currentTime);
  const previousCards = cards.filter(card => card.position + 4 < currentTime).sort((a, b) => b.position - a.position);

  return (
    <div class={style.headerContainer}>
      <LegalBanner
        show={showLegalBanner}
        testedAt={testedAt}
        releasedAt={releasedAt}
        reportLink={reportLink}
      />
      <div class={`${style.cardsContainer} ${showLegalBanner ? style.withLegalBanner : ''} `} style={{ display: thumbnailToogle ? 'none' : 'block' }}>
        <div class={`${style.notification} ${currentCard ? `${style.notificationVisible}` : ``}`}>
          {currentCard &&
            <Card
              className={`${style.notificationCard} ${show ? style.notificationCardHover : ``}`}
              {...currentCard}
              highStoryboard={highStoryboard}
              onClick={() => onClick(currentCard.position, currentCard.id)}
            />
          }
        </div>
        <div class={`${style.cardsList} ${currentCard ? `${style.cardsBottomAnimation}` : ``}`}>
          {previousCards.map((card, i) => (
            <Card
              key={card.id}
              {...card}
              time={card.position}
              onClick={() => onClick(card.position, card.id)}
              show={show}
              transitionDelay={150 * (i + 1)}
              highStoryboard={highStoryboard}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardsList;

CardsList.propTypes = {
  releasedAt: PropTypes.number,
  testedAt: PropTypes.number,
  show: PropTypes.bool,
  showLegalBanner: PropTypes.bool,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      time: PropTypes.number,
      thumbnail: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  currentTime: PropTypes.number,
  thumbnailToogle: PropTypes.bool,
  highStoryboard: PropTypes.array,
  reportLink: PropTypes.string,
};