import { getUrlArguments } from "./api";
import { URL_API } from "./config";

const sendSDKEvent = data => {
    if (window.parent) {
        try {
            window.parent.postMessage(
                JSON.stringify(data),
                decodeURIComponent({ origin: '', ...getUrlArguments() }.origin) || '*'
            );
        } catch (e) { }
    }
};

const eventsSent = [];

const sendEventDedup = (eventType, params = {}) => {
    const key = `${eventType}-${JSON.stringify(params)}`;
    if (!eventsSent.includes(key)) {
        eventsSent.push(key);
        sendEvent(eventType, params);
    }
}

const sendEvent = async (eventType, parameters = {}) => (fetch(`${URL_API}data/collect${parameters.session ? `?anonymous=1&session=${parameters.session}` : ''}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ ...parameters, event: eventType }),
    headers: { 'Content-Type': 'application/json' }
}).then(r => r.json())).catch(() => { });

let _projectId = null;
let _playerId = null;
let _session = null;
let _label = '';

let _lastProgress = 0;
let _nextProgress = -1;

// Skeepers tracker related
let __lastNonBlockProgress = 0;
let _lastProgressSecond = 0;
let _isVideoLoaded = false;

let _lastBuffering = 0;
let _bufferingStart = null;
let _bufferingList = [];

(() => {
    const delay = 5000;
    const urlArgs = getUrlArguments();
    if (!urlArgs.tracking) {
        return;
    }

    setInterval(() => {
        // Skip -1 it's triggered on preview
        if (Math.round(_nextProgress) !== Math.round(__lastNonBlockProgress) && _nextProgress !== -1) {
            __lastNonBlockProgress = _nextProgress
            sendSDKEvent( {
                type: 'cv:video_progress',
                video_id: _projectId,
                player_id: _playerId,
                video_time_second: _lastProgressSecond,
                video_time_percent: _nextProgress,
            });
        }

        if (Math.round(_nextProgress) > Math.round(_lastProgress)) {
            _lastProgress = Math.round(_nextProgress);
            sendEventDedup('VIDEO_PROGRESS', {
                project_id: _projectId,
                player_id: _playerId,
                progress: _nextProgress,
                label: _label,
                ...(_session ? { session: _session } : {}),
            });
        }
    }, delay);

    setInterval(() => {
        const maxBuffering = Math.max(...[0, ..._bufferingList, ...(_bufferingStart ? [Math.round((((new Date()).getTime() / 1000) - _bufferingStart) * 1000)] : [])])
        if (maxBuffering > _lastBuffering && maxBuffering > 0) {
            _lastBuffering = maxBuffering
            sendEventDedup('VIDEO_BUFFERING', {
                project_id: _projectId,
                player_id: _playerId,
                max_buffering: maxBuffering,
                label: _label,
                ...(_session ? { session: _session } : {}),
            });
        }
    }, delay);
})()

export const onEvent = (evt, projectId, arg = null, configId, project = null, session = null) => {
    const urlArgs = getUrlArguments();

    // Send events to skeepers tracker
    if (['SEEK', 'PLAY', 'PAUSE'].includes(evt)) {
        if (arg?.second !== undefined && arg?.percent !== undefined) {
            sendSDKEvent( {
                type: `cv:video_${evt.toLowerCase()}`,
                player_id: configId,
                video_id: projectId,
                video_time_second: arg.second,
                video_time_percent: arg.percent,
            });
        }
    }

    // Send video_load event to skeepers tracker
    if ('LOAD' === evt && !_isVideoLoaded) {
      _isVideoLoaded = true;
      sendSDKEvent({
        type: 'cv:video_init',
        player_id: configId,
        video_id: projectId,
        project,
      });
    }

    if (['PROGRESS'].includes(evt)) {
        if (_lastProgressSecond !== arg.second || 0) {
            _lastProgressSecond = arg.second || 0
        }
    }

    // Save as global
    _projectId = projectId;
    _playerId = configId;
    _session = session;
    _label = urlArgs.label || '';

    // Store buffering
    if (evt === 'BUFFERING' && arg) {
        _bufferingStart = (new Date()).getTime() / 1000;
    } else if (evt === 'BUFFERING' && !arg) {
        _bufferingList.push(Math.round((((new Date()).getTime() / 1000) - _bufferingStart) * 1000));
        _bufferingStart = null;
    } else if (evt === 'PAUSE') {
        _bufferingStart = null;
    }

    // Send tracking events
    if (urlArgs.tracking && ['PROGRESS'].includes(evt)) {
        _nextProgress = arg.percent || 0;
    } else if (urlArgs.tracking && [
        'LOAD',
        'PLAY',
        'RATING',
        'SHARE',
        'CTA_BUY_PRODUCT',
        'CTA_JOIN_COMMUNITY',
        'SUBTITLE',
        'SEEK_CARD',
        'FAST_FORWARD',
        'REWIND',
        'FULL_SCREEN_ENTER',
        'FULL_SCREEN_LEAVE',
        'SOUND_ON',
        'SOUND_OFF'
    ].includes(evt)) {
        sendEventDedup((urlArgs.tracking_event === 'VSG_PLAY' && evt === 'PLAY') ? 'VSG_PLAY' : `VIDEO_${evt}`, {
            ...(evt === 'PROGRESS' ? { progress: arg.percent } : {}),
            ...(evt === 'RATING' ? { rating: arg } : {}),
            ...(evt === 'SUBTITLE' ? { subtitle_id: arg } : {}),
            ...(evt === 'SEEK_CARD' ? { card_id: arg } : {}),
            ...(urlArgs.tracking_event === 'VSG_PLAY' && evt === 'PLAY' ? { vsg_id: urlArgs.vsg_id } : {}),
            ...(urlArgs.tracking_event !== 'VSG_PLAY' && evt === 'PLAY' ? { origin: urlArgs.computed_origin } : {}),
            project_id: projectId,
            player_id: configId,
            label: _label,
            ...(session ? { session } : {}),
        });
    }

    // Send SDK events
    if (['PLAY', 'PAUSE', 'END', 'PROGRESS'].includes(evt)) {
        sendSDKEvent({
            type: `VIDEO_${evt}`,
            ...(evt === 'PROGRESS' ? { progress: arg } : {}),
            project: {
                id: project.id || '',
                title: project.title || '',
                short_title: project.productTitle || '',
            },
        });
    }
}
