import { createRef, Component } from 'preact';
import PropTypes from 'prop-types';
import { durationInMinutes, floorValue } from "../../utils";
import ProgressBarPreview from '../ProgressBarPreview';
import style from "./style.module.css";

class ProgressBar extends Component {

  constructor(props) {
    super(props)
    this.state = { hoverPosition: null, mouseX: null }
    this.refRange = createRef();
    this.refHover = createRef();
    window.addEventListener('mouseup', () => this._onDocumentMouseUp());
    window.addEventListener('mousemove', (e) => this._onDocumentMouseMove(e));

    window.addEventListener('touchmove', (e) => this._onDocumentMouseMove(e));
    window.addEventListener('touchend', () => this._onDocumentMouseUp());
  }

  _updateHoverPosition(value) {
    if (value !== this.state.hoverPosition) {
      this.setState({ hoverPosition: value })
    }
  }

  _onMouseLeave() {
    this._updateHoverPosition(null);
  }

  _onMouseMove(e) {
    const mousePosition = (e.clientX || (e.touches[0].clientX)) - this.refRange.current.getBoundingClientRect().left - document.documentElement.getBoundingClientRect().left
    const progress = mousePosition / this.refRange.current.offsetWidth
    const newHoverPosition = (progress < 0 || progress > 1 || !this.props.duration) ? null : progress;
    this._updateHoverPosition(newHoverPosition);
  }

  _onMouseDown() {
    this.props.setIsMouseDown(true);
  }

  _onDocumentMouseUp() {
    let seekPosition = this.state.hoverPosition === null ? null : this.state.hoverPosition * this.props.duration;
    const cardHover = this.state.hoverPosition !== null && this.props.cards.find(card => Math.abs(card.position / this.props.duration - this.state.hoverPosition) < 0.005);
    seekPosition = cardHover ? cardHover.position : seekPosition;
    if (this.props.isMouseDown) {
      const mouse = (this.state.mouseX / 100) * this.props.duration;
      seekPosition = seekPosition ? seekPosition : mouse;
    }
    if (seekPosition !== null) {
      //console.log(`[Player] Seek to ${seekPosition}`);
      this.props.onClick(seekPosition, cardHover || null);
      this._updateHoverPosition(null);
    }
    this.props.setIsMouseDown(false);
  }

  _onDocumentMouseMove(e) {
    this.setState({ mouseX: (e.clientX || (e.touches[0].clientX)) * 100 / window.innerWidth });

    if (this.props.isMouseDown) {
      if (this.props.isPlaying) {
        this.props.onPlay();
      }
      const mouse = (this.state.mouseX / 100) * this.props.duration;
      const highThumbnails = this.props.highStoryboard.find(e => e.timeStart <= mouse && mouse < e.timeEnd);
      this.props.setForegroundStoryboard({...highThumbnails, time: mouse });
    }
  }

  render() {
    const positionHover = (() => {
      if (floorValue(this.state.hoverPosition * 100)) {
        return `${floorValue(this.state.hoverPosition * 100)}%`;
      } else if (this.props.isMouseDown && this.state.mouseX >= 0) {
        return `${this.state.mouseX}%`;
      }
      return '0%';
    })();
    const time = (() => {
      if (this.state.hoverPosition) {
        return this.state.hoverPosition * this.props.duration;
      } else if (this.props.isMouseDown && this.state.mouseX >= 0) {
        return (this.state.mouseX / 100) * this.props.duration;
      }
      return 0;
    })();
    const leftPosition = (() => {
      const pixels = parseFloat(positionHover) * window.innerWidth / 100;
      const width = this.refHover.current?.getBoundingClientRect().width;
      const containerPadding = this.refRange.current?.getBoundingClientRect().left;

      if (pixels - (width / 2) < 0) {
          return `${width / 2}px`;
      }
      if (pixels + (width / 2) > window.innerWidth) {
        return `${window.innerWidth - (width / 2) - (containerPadding * 2)}px`
      }
      return positionHover;
    })();

    return (
      <div class={style.container}
        onMouseMove={(e) => { this._onMouseMove(e) }}
        onMouseLeave={() => { this._onMouseLeave() }}
        onTouchMove={(e) => { this._onMouseMove(e) }}
        onTouchCancel={() => { this._onMouseLeave() }}
        onMouseDown={() => this._onMouseDown() }
        onTouchStart={() => this._onMouseDown() }
        ref={this.refRange}
        style={{ marginBottom: this.props.isMobile ? '0px' : '', padding: this.props.isMobile ? "9px 0 0 0" : '', height: this.props.isMobile ? 'fit-content' : '' }}
        draggable={false}
      >
        <div class={`${style.progressBarContainer}`} draggable={false}>
          {/* Progress */}
          <div class={style.progressBarProgress} style={{ transform: `scaleX(${this.props.duration <= 0 ? 0 : floorValue((this.props.isMouseDown ? time : this.props.currentTime) / this.props.duration)})` }} draggable={false} />

          {/* Cards */}
          {this.props.cards.map(card => <div key={card.id} class={`${style.marker} ${style.hover}`} style={{ left: `${floorValue(card.position / this.props.duration * 100)}%` }} />)}

          {/* Hover */}
          {(this.state.hoverPosition !== null || this.props.isMouseDown ) && <div class={`${style.progressHover}`} style={{ width: positionHover }} />}

          {
            (this.state.hoverPosition !== null || this.props.isMouseDown) &&
              <div ref={this.refHover} className={style.hoverContainer} style={{ left: leftPosition }}>
                <ProgressBarPreview time={time} lowStoryboard={this.props.lowStoryboard} highStoryboard={this.props.highStoryboard} />
                <div class={style.timeContainer}><div class={`${style.time} ${style.hover}`} draggable={false} >{durationInMinutes(time)}</div></div>
              </div>
          }
        </div>
      </div>
    )
  }
}

export default ProgressBar

ProgressBar.propTypes = {
  duration: PropTypes.number,
  currentTime: PropTypes.number,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    time: PropTypes.number,
  })),
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  show: PropTypes.bool,
};