import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import style from "./style.module.css";

export const ErrorNotFound = ({ t = () => { } }) => {
  return (
    <section class={`${style.container}`}>
      <p>{t("Video not found")}</p>
    </section>
  );
};

export default withTranslation()(ErrorNotFound);

ErrorNotFound.propTypes = {
  t: PropTypes.func
};
