import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useState } from 'preact/hooks';
import Button from '../Button'
import style from "./style.module.css";
import RatingStars from '../RatingStars';

export const ModalRate = ({ t = _ => _, onClick = () => { } }) => {
  const [rating, setRating] = useState(null);
  const handleClick = () => {
    if (rating !== null) {
      onClick(rating);
    }
  }
  return (
    <div class={style.modalRateContainer}>
      <h1>{t(`Do you find this video useful?`)}</h1>
      <p id={style.text}>{t('Our main concern is to help customers in their purchases')}</p>
      <RatingStars onChange={setRating} />
      <Button title={t('Save')} className={style.button} onClick={handleClick} disabled={rating === null} />
    </div>
  );
};

export default withTranslation()(ModalRate);

ModalRate.propTypes = {
  t: PropTypes.func,
  onClick: PropTypes.func
};
