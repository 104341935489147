import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Illustration from './assets/thanks.svg'
import style from "./style.module.css";

export const ModalThanks = ({title= '', text= ''}) => {
  return (
    <div class={style.modalThanksContainer}>
      <h1>{title}</h1>
      <p id={style.text}>{text}</p>
      <Illustration />
    </div>
    
  );
};

export default withTranslation()(ModalThanks);

ModalThanks.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};
