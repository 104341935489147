import i18n from "i18next";
import { makePlugins } from "@skeepers/i18n/i18next";
import { initReactI18next as react } from "react-i18next";

import { URL_API } from "./config";
import { getCacheDuration } from "./api";

const { backend, detector, postProcessor, initOptions } = makePlugins({
  url: URL_API,
  template: 'player',
  cacheDuration: getCacheDuration(),
});

i18n
  .use(backend)
  .use(detector)
  .use(postProcessor)
  .use(react)
  .init({
    ...initOptions,
    react: {
      useSuspense: false,
    },
  });

export const getLanguage = () => i18n.language;

export default i18n;
