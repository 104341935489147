import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Illustration from './assets/community.svg'
import Button from '../Button'
import style from "./style.module.css";

export const ModalParticipate = ({ t = _ => _, companyName = '', onClick = () => {} }) => {
  return (
    <div class={style.modalParticipateContainer}>
      <h1>{t(`Join the {{companyName}} community`, { companyName })}</h1>
      <p id={style.text}>{t('Make videos about your favorite products, you will be rewarded')}</p>
      <Illustration />
      <Button title={t('Join')} className={style.button} onClick={onClick} />
    </div>

  );
};

export default withTranslation()(ModalParticipate);

ModalParticipate.propTypes = {
  t: PropTypes.func,
  companyName: PropTypes.string,
  joinUrl: PropTypes.string
};
