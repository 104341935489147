import { useRef } from 'react';
import { getBackgroundStyleForForegroundImage } from '../../storyboardUtils';
import style from './style.module.css';

const ForegroundImage = ({ storyboard }) => {
    const ref = useRef();

    return (
        <div ref={ref} className={style.foregroundImageContainer} style={{ backgroundColor: storyboard?.path ? 'black' : '' }}>
            {storyboard && ref.current && <div style={getBackgroundStyleForForegroundImage(storyboard, ref.current.getBoundingClientRect())} className={style.foregroundImage} />}
        </div>
    );
};

export default ForegroundImage;