import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN, NODE_ENV } from './config';

const devEnv = !NODE_ENV || NODE_ENV === 'development';

mixpanel.init(MIXPANEL_TOKEN);

const actions = {
  track: (name, props) => {
    if (!devEnv) {
      mixpanel.track(name, {
        ...props,
        env: NODE_ENV,
      });
    }
  },
};

export const Mixpanel = actions;
