import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LanguagesLine from "../LanguagesLine";
import style from "./style.module.css";
import { getFullLanguageName } from '../../utils';
import {useEffect} from "preact/hooks";

export const LanguagesModal = ({ show = false, subtitles = [], selectedSubtitle = false, onSubtitlesChange = () => { }, t = _ => _, onLanguages = () => { } }) => {

  const onDisableSubtitles = () => {
    onSubtitlesChange(false)
  }

  const canDisplaySubtitles = subtitles.length > 0;

  useEffect(() => {
    if (!show) return;

    document.getElementById('subtitles-modal').firstElementChild.focus();
  }, [show]);

  const handleKeyboardNavigation = (e) => {
    e.preventDefault();

    if (e.key === 'Tab') {
      if (e.target.nextElementSibling && !e.shiftKey) {
        e.target.nextElementSibling.focus();
        return;
      }

      if (e.target.previousElementSibling && e.shiftKey) {
        e.target.previousElementSibling.focus();
        return;
      }

      if (!e.target.nextElementSibling && !e.shiftKey || !e.target.previousElementSibling && e.shiftKey) {
        document.querySelector('[aria-type="SUBTITLES"]')?.focus();
        onDisableSubtitles();
      }
    }

    if (e.key === ' ' || e.key === 'Enter') {
      onSubtitlesChange(e.target.getAttribute('aria-subtitleId'));
      document.querySelector('[aria-type="SUBTITLES"]')?.focus();
    }
  }

  return (
    <>
      {canDisplaySubtitles && <div style={{ display: show ? "block" : "none", backgroundColor: 'rgba(0,0,0,0.6)' }}>
        <div id="subtitles-modal" class={style.languagesModalContainerDesktop} tabIndex={show ? 0 : -1} aria-label={t('Subtitles language selection modal')} onKeyDown={handleKeyboardNavigation}>
          <LanguagesLine typeIcon='SUBTITLES' languageName={t('Disable')} selected={!selectedSubtitle} onClick={onDisableSubtitles} titleAccessibility={t('Disable')} />
          {
            subtitles.map(subtitle => (
              <LanguagesLine key={subtitle.id} typeIcon='SUBTITLES' subtitleId={subtitle.id} languageName={getFullLanguageName(subtitle.language)} onClick={onSubtitlesChange} selected={selectedSubtitle === subtitle.id} titleAccessibility={getFullLanguageName(subtitle.language)} alpha2Code={subtitle.alpha2code} />
            ))
          }
        </div>
        <div class={style.backgroundMobileFullContainer} onClick={onLanguages} />
        <div class={style.languagesModalContainerMobileFullContainer} >
          <h1>{t('Change the subtitle language')}</h1>
          <div class={style.languagesModalContainerMobile}>
            <LanguagesLine typeIcon='SUBTITLES' languageName={t('Disable')} selected={!selectedSubtitle} onClick={onDisableSubtitles} titleAccessibility={t('Disable')} />
            {subtitles.map(subtitle => (
              <LanguagesLine key={subtitle.id} typeIcon='SUBTITLES' subtitleId={subtitle.id} languageName={getFullLanguageName(subtitle.language)} onClick={onSubtitlesChange} selected={selectedSubtitle === subtitle.id} titleAccessibility={getFullLanguageName(subtitle.language)} alpha2Code={subtitle.alpha2code} />
            ))}
          </div>
        </div>
      </div>}
    </>
  );
};

export default withTranslation()(LanguagesModal);

LanguagesModal.propTypes = {
  show: PropTypes.bool,
  subtitles: PropTypes.array,
  selectedSubtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onSubtitle: PropTypes.func,
  t: PropTypes.func,
  onLanguages: PropTypes.func
};
