import PropTypes from 'prop-types';
import PlayerIcon from "../PlayerIcon";
import style from "./style.module.css";

export const ActionButton = ({typeIcon = '', titleButton = '',color = '',  onClick = () => { }, className = '', titleAccessibility = '', styleProps = { }}) => {
  return (
    <div class={`${style.actionButtonContainer} ${className}`} style={styleProps}>
      <PlayerIcon type={typeIcon} color={color} clickHandle={onClick} titleAccessibility={titleAccessibility} />
      <p>{titleButton}</p>
    </div>
  );
};

export default ActionButton;

ActionButton.propTypes = {
  onClick: PropTypes.func,
  titleButton: PropTypes.string,
  typeIcon: PropTypes.string,
  className: PropTypes.string,
  titleAccessibility: PropTypes.string,
};
