import { faBackward, faForward, faCheck, faTimes as Cross, faPlay, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faCrown, faShareAlt, faStar as faStarEmpty, faCartPlus } from '@fortawesome/pro-regular-svg-icons'
import { faExpand, faPause, faUndoAlt, faCompress, faVolumeMute, faVolume, faStar as faStarFill, faCreditCardBlank as Subtitles } from '@fortawesome/pro-solid-svg-icons'
import { faFacebookF, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faCartPlus as faCartPlusLight, faCommentAltExclamation, faExternalLink } from '@fortawesome/pro-light-svg-icons'

const icons = {
  ADD_TO_BASKET: {
    Icon: faCartPlus
  },
  ADD_TO_BASKET_LIGHT: {
    Icon: faCartPlusLight
  },
  FAST_BACK: {
    Icon: faBackward
  },
  FAST_FORWARD: {
    Icon: faForward
  },
  CHECK: {
    Icon: faCheck
  },
  CROSS: {
    Icon: Cross
  },
  CROWN_EMPTY: {
    Icon: faCrown
  },
  FULL_SCREEN: {
    Icon: faExpand
  },
  PAUSE: {
    Icon: faPause
  },
  PLAY: {
    Icon: faPlay
  },
  REFRESH: {
    Icon: faUndoAlt
  },
  SHARE_EMPTY: {
    Icon: faShareAlt
  },
  SHARE_FACEBOOK: {
    Icon: faFacebookF
  },
  SHARE_TWITTER: {
    Icon: faTwitter
  },
  SHARE_WHATSAPP: {
    Icon: faWhatsapp
  },
  SHRINK: {
    Icon: faCompress
  },
  SOUND_OFF: {
    Icon: faVolumeMute
  },
  SOUND_ON: {
    Icon: faVolume
  },
  SUBTITLES: {
    Icon: Subtitles
  },
  STAR_EMPTY: {
    Icon: faStarEmpty
  },
  STAR_FILL: {
    Icon: faStarFill
  },
  INFO: {
    Icon: faInfoCircle
  },
  EXTERNAL_LINK: {
    Icon: faExternalLink,
  },
  COMMENT_EXCLAMATION: {
    Icon: faCommentAltExclamation,
  }
};

export default icons;
