import { getLanguage } from '../i18n';
import { REPORT_LINK } from '../config';

export const getReportLink = ({
  clientName = '',
  platformName = '',
  creatorName = '',
  url = '',
  productReferences = [],
}) => {
  const initialReportLink = REPORT_LINK;
  const initialParams = new URLSearchParams(new URL(initialReportLink).search);
  const client_page_url = (window.location !== window.parent.location)
    ? document.referrer : document.location.href;

  const store_name = platformName || '';

  const product_ean = Array.isArray(productReferences) ? productReferences.join(',') : '';
  const language = getLanguage();

  if (language) initialParams.set('lang', language)
  if (store_name) initialParams.set('d:store_name', store_name);
  if (product_ean) initialParams.set('d:product_ean', product_ean);
  if (clientName) initialParams.set('d:client_name', clientName);
  if (client_page_url) initialParams.set('d:client_page_url', client_page_url);
  if (creatorName) initialParams.set('d:creator_name', creatorName);
  if (url) initialParams.set('d:video_url', url);

  const [baseUrl] = initialReportLink.split('?');
  return `${baseUrl}?${initialParams.toString()}`;
};